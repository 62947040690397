



@import url(assets/fonts/dana/css/fontiran.css);
@import url(assets/fonts/peyda/css/fontiran.css);
@import url(assets/fonts/digital/font.css);

@import url(assets/font-icon/css/all.css);

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/styles/angular/custom';

@import 'assets/styles/custom';


html, body { height: 100%; }
body { margin: 0; font-family: peyda, dana, Roboto, font-awesome, "Helvetica Neue", sans-serif; }

* {
  -webkit-user-select: none;
  user-select: none;
}

.font-dana {
  font-family: dana, Roboto, font-awesome, "Helvetica Neue", sans-serif;
}

.font-digital {
  font-family: digital;
  font-weight: 200;
}



