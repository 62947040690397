@import "tailwindcss/utilities";
@import 'tailwindcss/components';

.fa-align {
  vertical-align: middle;
}

.card {
  @apply bg-gray-200 text-black my-4 p-4 rounded-lg dark:bg-gray-900 dark:text-white;

  &.card-primary {
    @apply bg-blue-500 text-white;
  }

  &.card-secondary {
    @apply bg-orange-500 text-white;
  }
}

button.btn:not(.custom-button) {
  @apply flex items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4  focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800;

  &.primary {
    @apply bg-primary-500 text-white;
  }

  &.warning {
    @apply bg-yellow-500 text-white;
  }

  &.danger {
    @apply bg-red-500 text-white;
  }

  &.info {
    @apply bg-blue-500 text-white;
  }

  &.default {
    @apply bg-gray-500 text-white;
  }

  &[disabled] {
    @apply bg-gray-300 text-gray-500 cursor-not-allowed;
  }
}

input[type=number] {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
}


.mat-ripple {
  overflow: hidden !important;
}

.error-notification {
  --mdc-snackbar-container-color: rgb(240 82 82 / var(--tw-bg-opacity));
}

.success-notification {
  --mdc-snackbar-container-color: rgb(14 159 110 / var(--tw-bg-opacity));
}

.info-notification {
  --mdc-snackbar-container-color: rgb(0 122 255 / var(--tw-bg-opacity));
}
