/**
*
*	Name:			DANA Fonts
*	Version:			3.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		nov 12, 2021
*	Updated on:		nov 12, 2021
*	Website:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت دانا یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
DANA fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*
**/
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: normal;
  src: url('../woff2/PeydaWeb-Regular.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-Regular.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: bold;
  src: url('../woff2/PeydaWeb-Bold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-Bold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 100;
  src: url('../woff2/PeydaWeb-Thin.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-Thin.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 200;
  src: url('../woff2/peydaWeb-extralight.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/peydaWeb-extralight.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 300;
  src: url('../woff2/peydaWeb-light.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/peydaWeb-light.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 500;
  src: url('../woff2/PeydaWeb-Medium.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-Medium.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 600;
  src: url('../woff2/PeydaWeb-SemiBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-SemiBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 800;
  src: url('../woff2/PeydaWeb-ExtraBold.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-ExtraBold.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: peyda;
  font-style: normal;
  font-weight: 900;
  src: url('../woff2/PeydaWeb-Black.woff2') format('woff2'),  /* FF39+,Chrome36+, Opera24+*/
       url('../woff/PeydaWeb-Black.woff') format('woff');  /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
